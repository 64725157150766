import { scan } from "react-scan";
import {
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useRouteError,
  useRouteLoaderData,
  useMatches,
} from "@remix-run/react";
import type { LoaderFunction } from "@remix-run/node";
import "./tailwind.css";

// Import rootAuthLoader
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { ClerkApp, SignedIn, SignedOut, SignInButton } from "@clerk/remix";
import { Button } from "./components/shadcn/button";
import config from "./utils/config";
import { useEffect } from "react";
import { ErrorPage } from "./components/error";
import { captureRemixErrorBoundaryError, SentryMetaArgs, withSentry } from "@sentry/remix";
import * as Sentry from "@sentry/remix";

// Export as the root route loader
export const loader: LoaderFunction = (args) =>
  rootAuthLoader(args, ({ request }) => {
    const { userId } = request.auth;
    // fetch data
    return {
      userId,
      ENV: {
        LOANX_APP_URL_BASE: config.appUrlBase,
        NODE_ENV: process.env.NODE_ENV,
        SENTRY_ENV: config.sentryEnvironment,
      },
    };
  });

export const meta = ({ data }: SentryMetaArgs<MetaFunction<typeof loader>>) => {
  return [
    { title: "LoanX.ai App" },
    {
      property: "og:title",
      content: "LoanX.ai App",
    },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1",
    },
    {
      name: "sentry-trace",
      content: data?.sentryTrace,
    },
    {
      name: "baggage",
      content: data?.sentryBaggage,
    },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { ENV } = useRouteLoaderData<typeof loader>("root") || {};
  console.log("ENV", ENV);
  useEffect(() => {
    Sentry.init({
      dsn: "https://9c88185c2849d2530c8a1544a0907fd4@o4507896412700672.ingest.us.sentry.io/4507901808017408",
      environment: ENV.SENTRY_ENV,
      enabled: !!ENV.SENTRY_ENV,
      tracesSampleRate: 1,

      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
      ],
    });

    // Make sure to run React Scan after hydration
    scan({
      enabled: ENV.NODE_ENV === "development",
    });
  }, [ENV]);

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;800&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Space+Mono&display=swap" rel="stylesheet" />
      </head>
      <body className="overflow-x-hidden md:overflow-hidden">
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function SignedOutPage() {
  return (
    <div className="h-screen bg-grey-400 flex items-center justify-center">
      <div className="p-4">
        <SignInButton>
          <Button>Sign In</Button>
        </SignInButton>
      </div>
      {/* <div className="p-4">
        <SignUpButton>
          <Button>Sign Up</Button>
        </SignUpButton>
      </div> */}
    </div>
  );
}

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/loans");
    }
  }, [location]);

  return (
    <div className="flex-col flex">
      <SignedIn>
        <Outlet />
      </SignedIn>
      <SignedOut>
        <SignedOutPage />
      </SignedOut>
    </div>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  const sentryCode = captureRemixErrorBoundaryError(error);
  return <ErrorPage error={error} code={sentryCode} />;
};

export default ClerkApp(withSentry(App), {
  appearance: {
    layout: {
      unsafe_disableDevelopmentModeWarnings: true,
    },
    variables: {
      // colorPrimary: "#3B82F6",
      // colorText: "white",
      borderRadius: "0.2rem",
    },
  },
});
